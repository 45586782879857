<template>
	<div class="shop">
		<div class="shop_kong"></div>
		<div class="shop_right">
			<div class="right_title">店铺信息</div>
			<div class="message_item">
				<div class="hao">*</div>
				<div class="shop_name">店铺名称</div>
				<CusInput class="inp" v-if="is_edit" :value="shop_name" :max_num="20"
					@chenginput="chenginput($event,'shop_name')" />
			</div>
			<!-- <div class="message_item">
                <div class="hao">*</div>
                <div class="shop_name">店铺ID</div>
                <CusInput class="inp" :name="'name1'" v-model="shop_id" :max_num="20" @chenginput="chenginput"/> 
            </div> -->
			<div class="message_item">
				<div class="hao">*</div>
				<div class="shop_name">店铺标志</div>
			</div>
			<div class="upload">
				<cus-upload class="img_upload" v-if="is_edit" :accept="'image/png,image/gif,image/jpg,image/jpeg'"
					:src="have_tu" @change="cha_img($event)"></cus-upload>
			</div>
			<div class="message_item">
				<div class="hao">*</div>
				<div class="shop_name">联系地址</div>
			</div>
			<el-select class="select" v-model="value" placeholder="省" @change="changecity">
				<el-option v-for="(item,index) in city_list" :key="index" :label="item.name" :value="index">
				</el-option>
			</el-select>
			<el-select class="select" v-model="value1" placeholder="市" @change="changecity1">
				<el-option v-for="(item,index) in city_list1" :key="index" :label="item.name" :value="index">
				</el-option>
			</el-select>
			<el-select class="select" v-model="value2" placeholder="区">
				<el-option v-for="(item,index) in city_list2" :key="index" :label="item.name" :value="index">
				</el-option>
			</el-select>
			<el-input class="inp1" :autosize="{ minRows: 2, maxRows: 4}" type="textarea" :rows="2" placeholder="详细地址"
				v-model="textarea">
			</el-input>
			<div class="comfirn_btn">
				<el-button class="btn" @click="com_btn">保存</el-button>
			</div>
		</div>
		<div class="shop_kong"></div>
	</div>
</template>
<script>
	import CusInput from '../from/cus-input.vue';
	import city_list from '../../common/city.data.js'
	import CusUpload from "../from/cus-upload.vue";
	import axios from "../../common/axios";
	export default {
		components: {
			CusInput,
			CusUpload,
		},
		data() {
			return {
				text: '',
				shop_name: '', //店铺名称
				shop_id: '', //店铺ID
				have_tu: '', //原来是否有店铺标志图片
				vla_from: [],
				dialogImageUrl: '',
				dialogVisible: false,
				value: '',
				value1: '',
				value2: '',
				textarea: '',
				city_list: city_list, // 省
				city_list1: [], // 市
				city_list2: [], // 区
				is_edit: false,
			}
		},
		mounted() {
			this.get_store();
		},
		methods: {
			get_store() {
				
				let shop_name = "";
				let have_tu = "";
				let province = "";
				let city = "";
				let area = "";
				let textarea = "";
				this.is_edit = false;

				axios.post("/adminshop/user/get_store.html").then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						shop_name = data.shop_name;
						have_tu = data.have_tu;
						province = data.province;
						city = data.city;
						area = data.area;
						textarea = data.textarea;
					} else {
						console.log(res.error);
					}
					this.shop_name = shop_name;
					this.have_tu = have_tu;
					this.province = province;
					if (province != null && province != "") {
						this.city_list.forEach((item, key) => {
							if (item.name == province) {
								this.value = key;
								this.city_list1 = item.sub;
							}
						});
					}
					if (city != null && city != "") {
						this.city_list1.forEach((item, key) => {
							if (item.name == city) {
								this.value1 = key;
								this.city_list2 = item.sub;
							}
						});
					}
					if (area != null && area != "") {
						this.city_list2.forEach((item, key) => {
							if (item.name == area) {
								this.value2 = key;
							}
						});
					}

					this.textarea = textarea;
					this.is_edit = true;
				});
			},
			chenginput(e, name) {
				let value = e.value;
				this[name] = value
			},
			changecity(e) {
				this.city_list1 = this.city_list[e].sub
				this.city_list2 = []
				this.value2 = ''
				this.value1 = ''
			},
			changecity1(e) {
				this.city_list2 = this.city_list1[e].sub
				this.value2 = ''
			},
			cha_img(e) {
				this.have_tu = e.value;
			},
			com_btn() {
				this.$confirm('确定要保存这些资料吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.post_sub();
				}).catch(() => {

				});
			},
			post_sub() {
				let {
					shop_name,
					have_tu,
					value,
					value1,
					value2,
					textarea
				} = this;
				let province = "";
				let city = "";
				let area = "";
				if ((value != '' || value == 0) && this.city_list.length > 0) {
					province = this.city_list[value].name;
				}
				if ((value1 != '' || value1 == 0) && this.city_list1.length > 0) {
					city = this.city_list1[value1].name;
				}
				if ((value2 != '' || value2 == 0) && this.city_list2.length > 0) {
					area = this.city_list2[value2].name;
				}

				axios.post("/adminshop/user/add_store.html",{
					shop_name,
					have_tu,
					province,
					city,
					area,
					textarea
				}).then((res) => {
					let success = res.success;
					let data = res.data;
					if (success) {
						if (data.status == 1) {
							this.$message({
								type: "success",
								message: data.msg,
							});
						} else {
							this.$message({
								type: "error",
								message: data.msg,
							});
						}
					} else {
						console.log(res.error);
					}
				});

			}
		},
	}
</script>

<style scoped>
	@import './shop_information.css';

	.el-select select {
		border-radius: 20px;
	}
</style>
